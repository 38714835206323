"use client";

import type { ReactNode } from "react";
import classNames from "classnames";

import { Button as CurrencyButton } from "@repo/nerdwallet-components";

import styles from "./Button.module.less";

interface ButtonProps {
  children: ReactNode;
  href?: string;
  primary?: boolean;
  target?: string;
  ctaType?: string;
  className?: string;
}

const Button = ({
  children,
  href,
  target,
  primary,
  ctaType,
  className,
}: ButtonProps) => {
  return (
    <div
      className={classNames(
        styles.wrapper,
        styles.accessibility,
        styles.accent,
        className
      )}
    >
      <CurrencyButton
        href={href}
        target={target}
        primary={primary}
        data-cta-type={ctaType}
      >
        {children}
      </CurrencyButton>
    </div>
  );
};

export default Button;
